<template>
  <div id="website-form" class="flex-column p-0 w-100 px-2 p-2" v-if="site !== null" :class="[firstRun === false ? 'd-flex' : 'd-none', { 'container': !isFramed } ]">
    <!-- logo -->
    <div class="d-flex justify-content-center" v-if="site.logo && (typeof $route.query.disable_logo === 'undefined' || $route.query.disable_logo === '0' || $route.query.disable_logo === 'false')">
      <img :src="site.logo" class="mb-3" alt="Site logo" />
    </div>

    <!-- meeting created -->
    <div class="text-center mt-2" v-if="meetingCreated">
      <h1 v-if="typeof $route.query.disable_site_name === 'undefined' || $route.query.disable_site_name === '0' || $route.query.disable_site_name === 'false'" class="text-center mt-2 mb-3">{{ site.name }}</h1>
      <p>
        {{ $t('Iframe/WebsiteForm.The meeting is scheduled on {DATE} from {FROM_TIME} till {TILL_TIME}', { DATE: moment(formPost.startDate).format($t('Formats.Date')), FROM_TIME: formPost.timeSlot.split('_')[0], TILL_TIME: formPost.timeSlot.split('_')[1] }) }}
      </p>
      <p>
        {{ site && site.website_form_sent_text && site.website_form_sent_text[$i18n.locale] ? site.website_form_sent_text[$i18n.locale] : $t('Iframe/WebsiteForm.The appointment is scheduled') }}
      </p>
    </div>

    <!-- meeting form -->
    <form method="post" @submit.prevent="createAppointment" v-show="!meetingCreated">
      <div class="row">
        <div class="col-sm-12 col-md-9">
          <!-- arrow back to overview -->
          <div class="d-inline-flex mb-4 font-size-px-20 cursor-pointer" @click="$router.push({ name: prevRoute })" v-if="prevRoute && ['form_plannen_overview', 'form_schedule_overview'].includes(prevRoute)">
            <font-awesome-icon :icon="['fal', 'arrow-left']" />
          </div>
          <!-- header text -->
          <h1 v-if="typeof $route.query.disable_site_name === 'undefined' || $route.query.disable_site_name === '0' || $route.query.disable_site_name === 'false'" class="mt-2 mb-3">{{ site.name }}</h1>
          <p class="mb-2">
            {{ site && site.website_form_header_text && site.website_form_header_text[$i18n.locale] ? site.website_form_header_text[$i18n.locale] : $t('Iframe/WebsiteForm.Fill out the form below to schedule an appointment') }}
          </p>
        </div>
        <!-- language switcher -->
        <div class="col-sm-12 col-md-3" v-if="false">
          <p class="mt-1" v-if="typeof $route.query.disable_language_switcher === 'undefined' || $route.query.disable_language_switcher === 'false'">
            <component class="ml-auto w-px-150" :is="languageSwitcherComponent"></component>
          </p>
        </div>
      </div>
      <!-- datepicker -->

      <div class="form-group row mb-1">
        <label class="d-flex col-12 col-sm-4 pb-0 col-form-label font-weight-bold" for="start_date" v-if="typeof $route.query.basic_datepicker !== 'undefined' && $route.query.basic_datepicker === 'true'">{{ $t('Iframe/WebsiteForm.Date') }}</label>
        <div class="m-0" :class="[typeof $route.query.basic_datepicker !== 'undefined' && $route.query.basic_datepicker === 'true' ? 'col-12 col-sm-8' : 'col-12 h-px-500']">
          <WSDCalendar
            :isLoadingDates="isLoadingDates"
            :startTime="form.startDate"
            :inline="true"
            :shouldDisableWeekends="false"
            specialDateClass='specialDay'
            :activeDayBackgroundColor='site && site.website_form_theme_background_color'
            :activeDayTextColor='site && site.website_form_theme_contrast_color'
            :highlightDates="enabledDates"
            @setSelectedDate="setSelectedDate"
            @setSelectedMonthYear="setSelectedMonthYear">
            </WSDCalendar>
        </div>
      </div>
      <!-- availableDates found -->
      <template v-if="availableDates.length !== 0 && selectableTimes.length !== 0">
        <!-- timepicker basic -->
        <div class="form-group row mb-1" v-if="typeof $route.query.basic_timepicker !== 'undefined' && $route.query.basic_timepicker === 'true'">
          <label class="d-flex col-12 col-sm-4 pb-0 col-form-label font-weight-bold" for="time_slot">{{ $t('Iframe/WebsiteForm.Time') }}</label>
          <div class="col-12 col-sm-8 m-0">
            <select class="form-control" :class="[{ 'border-danger': formError.showTimeSlotRequired }]" id="time_slot" name="time_slot" ref="time_slot" v-model="form.timeSlot">
              <option :value="moment(timeslot.start).format('HH:mm') + '_' + moment(timeslot.end).format('HH:mm')" v-for="timeslot in this.selectableTimes" :key="timeslot.start">{{ moment(timeslot.start).format($t('Formats.Time')) }} {{ $t('Iframe/WebsiteForm.till') }} {{ moment(timeslot.end).format($t('Formats.Time')) }}</option>
            </select>
          </div>
        </div>
        <!-- timepicker buttons -->
        <div class="form-group row flex-row mx-0 p-0 mt-3 mb-3" v-else>
          <div class="col-12 col-sm-6 col-md-3 d-flex p-1" v-for="timeslot in this.selectableTimes" :key="timeslot.start">
            <label class="timeslot d-flex btn btn-block btn-secondary btn-white align-items-center justify-content-center border border-input-border p-0 m-0" :class="{ 'active': form.timeSlot === moment(timeslot.start).format('HH:mm') + '_' + moment(timeslot.end).format('HH:mm') }">
              <input v-model="form.timeSlot" type="radio" name="select_time" class="opacity-0 mr-2 margin-min-left-15" :value="moment(timeslot.start).format('HH:mm') + '_' + moment(timeslot.end).format('HH:mm')"> {{ moment(timeslot.start).format($t('Formats.Time')) }} {{ $t('Iframe/WebsiteForm.till') }} {{ moment(timeslot.end).format($t('Formats.Time')) }}
            </label>
          </div>
        </div>
        <!-- name -->
        <div class="form-group row mb-1">
          <label class="required d-flex col-12 col-sm-4 pb-0 col-form-label font-weight-bold" for="name">
            {{ $t('Iframe/WebsiteForm.Name') }}
          </label>
          <div class="col-12 col-sm-8 m-0">
            <input spellcheck="false" type="text" class="form-control" :class="[{ 'is-invalid': formError.showNameRequired }]" id="name" name="name" ref="name" v-model="form.name">
            <div class="invalid-feedback">
              {{ $t('Iframe/WebsiteForm.Invalid name') }}
            </div>
          </div>
        </div>
        <!-- email -->
        <div class="form-group row mb-1">
          <label class="required d-flex col-12 col-sm-4 pb-0 col-form-label font-weight-bold" for="email">{{ $t('Iframe/WebsiteForm.Email') }}</label>
          <div class="col-12 col-sm-8 m-0">
            <input spellcheck="false" type="email" class="form-control" :class="[{ 'is-invalid': formError.showEmailRequired }]" id="email" name="email" ref="email" v-model="form.email">
            <div class="invalid-feedback">
              {{ $t('Iframe/WebsiteForm.Invalid email address') }}
            </div>
          </div>
        </div>
        <!-- phone -->
        <div class="form-group row mb-1">
          <label class="d-flex col-12 col-sm-4 pb-0 col-form-label font-weight-bold" for="phone">{{ $t('Iframe/WebsiteForm.Phone') }}</label>
          <div class="col-12 col-sm-8 m-0">
            <input spellcheck="false" type="tel" class="form-control" :class="[{ 'is-invalid': formError.showPhoneInvalid }]" id="phone" name="phone" ref="phone" v-model="form.phone">
            <div class="invalid-feedback">
              {{ $t('Iframe/WebsiteForm.Invalid phone number') }}
            </div>
          </div>
        </div>
        <div v-if="site.custom_fields && site.custom_fields.length">
          <div class="form-group row mb-1"  v-for="(input, index) in site.custom_fields" :key="index">
            <label class="d-flex col-12 col-sm-4 pb-0 col-form-label font-weight-bold" for="phone">{{input.fieldName}}</label>
            <div class="col-12 col-sm-8 m-0">
              <input type="text" class="form-control" v-model="custom_fields[index]" :required="input.required" :name="input.fieldValue" :ref="input.fieldValue">
            </div>
          </div>
        </div>
        <!-- comment -->
        <div class="form-group row mb-1">
          <label class="d-flex col-12 col-sm-4 pb-0 col-form-label font-weight-bold" for="name">{{ $t('Iframe/WebsiteForm.Comment') }}</label>
          <div class="col-12 col-sm-8 m-0">
            <textarea type="text" class="form-control h-px-120" id="remarks" name="comment" ref="comment" v-model="form.comment"></textarea>
          </div>
        </div>
        <!-- buttons -->
        <div class="row">
          <div class="col-12 col-sm-8 offset-sm-4">
            <div class="d-flex d-sm-block mt-3 mb-3 justify-content-center">
              <button type="submit" class="submit btn btn-lg btn-primary" :class="{ disabled: postMeetingButtonDisabled }" :disabled="postMeetingButtonDisabled" v-if="postMeetingButtonDisabled === false">
                {{ site && site.website_form_button_text && site.website_form_button_text[$i18n.locale] ? site.website_form_button_text[$i18n.locale] : $t('Iframe/WebsiteForm.Schedule appointment') }}
              </button>
              <button type="submit" class="submit btn btn-lg btn-primary disabled" disabled="disabled" v-else><font-awesome-icon :icon="['fal', 'spinner']" spin role="presentation" /> {{ $t('Iframe/WebsiteForm.Loading') }}</button>
            </div>
          </div>
        </div>
      </template>
    </form>

    <!-- loading -->
    <div v-if="loadingNextMonth" class="d-flex justify-content-center font-size-rem-18 mt-2">
      <font-awesome-icon :icon="['fal', 'spinner']" spin role="presentation" />
    </div>

    <!-- no results found -->
    <div v-if="availableDates.length === 0 && loadingNextMonth === false" class="mt-2">
      {{ site && site.website_form_no_results_text && site.website_form_no_results_text[$i18n.locale] ? site.website_form_no_results_text[$i18n.locale] : $t('Iframe/WebsiteForm.There are no appointment options in the specified period') }}
    </div>
  </div>
</template>

<script>
import 'iframe-resizer/js/iframeResizer.contentWindow.min'

export default {
  name: 'WebsiteForm',
  data () {
    return {
      isLoadingDates: false,
      prevRoute: null,
      year: this.moment().year(),
      month: this.moment().month() + 1,
      site: null,
      postMeetingButtonDisabled: false,
      firstRun: true,
      loadingNextMonth: false,
      meetingCreated: false,
      generalError: false,
      formError: {
        showStartDateRequired: false,
        showTimeSlotRequired: false,
        showNameRequired: false,
        showEmailRequired: false,
        showPhoneInvalid: false
      },
      form: {
        startDate: this.moment().add(1, 'minutes'),
        timeSlot: '',
        name: '',
        email: '',
        phone: '',
        comment: '',
        subject: ''
      },
      formPost: {},
      availableDates: [],
      availableTimes: Object,
      selectableTimes: [],
      availableTimesInterval: null,
      isFramed: null,
      custom_fields: []
    }
  },
  methods: {
    shadeColor (R, G, B, percent) {
      R = parseInt(R * (100 + percent) / 100)
      G = parseInt(G * (100 + percent) / 100)
      B = parseInt(B * (100 + percent) / 100)

      R = (R < 255) ? R : 255
      G = (G < 255) ? G : 255
      B = (B < 255) ? B : 255

      let RR = ((R.toString(16).length === 1) ? '0' + R.toString(16) : R.toString(16))
      let GG = ((G.toString(16).length === 1) ? '0' + G.toString(16) : G.toString(16))
      let BB = ((B.toString(16).length === 1) ? '0' + B.toString(16) : B.toString(16))

      return '#' + RR + GG + BB
    },
    async createAppointment () {
      this.postMeetingButtonDisabled = true

      if (this.validateForm() === false) {
        this.postMeetingButtonDisabled = false
        return false
      }

      const startDate = this.moment(this.form.startDate).format('YYYY-MM-DD')
      const startTime = this.form.timeSlot.split('_')[0]
      const endTime = this.form.timeSlot.split('_')[1]

      const start = this.moment(startDate + ' ' + startTime).toISOString(true)
      const end = this.moment(startDate + ' ' + endTime).toISOString(true)

      const participants = []

      const addParticipant = {
        'name': this.form.name,
        'email': this.form.email,
        'locale': this.$i18n.locale
      }

      if (this.form.phone !== '') {
        addParticipant.phone = this.form.phone.replace(/-/g, '')
      }

      participants.push(addParticipant)

      this.site.custom_fields.forEach((field, index) => {
        field.fieldValue = this.custom_fields[index]
      })
      const postData = {
        'participants': participants,
        'site': this.$route.params.site,
        'is_appointment': true,
        'time_start': start,
        'time_end': end,
        'subject': '',
        'send_sms': false,
        'send_email': true,
        'custom_fields': this.site.custom_fields
      }

      if (this.form.comment !== '') {
        postData.comment = this.form.comment
      }
      const meeting = await this.$axiosSite.post(this.$axiosSite.routes.meeting_personal, postData)

      if (meeting.status && meeting.status === 200) {
        // await this.$axiosSite.patch(this.$axiosSite.routes.meeting_personal + '/' + meeting.data.result.id, postData)
        this.formPost = JSON.parse(JSON.stringify(this.form))
        this.meetingCreated = true
        this.$utils.postMessageToParent('MEETING_APPOINTMENT_SCHEDULED', { meeting_id: meeting.data.result.id })
      } else {
        this.generalError = true
      }

      this.postMeetingButtonDisabled = false

      return true
    },
    validateForm () {
      let error = false

      this.formError.showStartDateRequired = false
      if (this.form.startDate === null) {
        this.formError.showStartDateRequired = true
        error = true
      }

      this.formError.showTimeSlotRequired = false
      if (this.form.timeSlot === '') {
        this.formError.showTimeSlotRequired = true
        error = true
      }

      this.formError.showNameRequired = false
      if (this.form.name === '') {
        this.formError.showNameRequired = true
        error = true
      }

      this.formError.showEmailRequired = false
      if (this.form.email === '') {
        this.formError.showEmailRequired = true
        error = true
      }

      this.formError.showPhoneInvalid = false
      // if (this.form.phone !== '' && !this.validatePhone(this.form.phone)) {
      //   this.formError.showPhoneInvalid = true
      //   error = true
      // }

      return !error
    },
    validatePhone (phone) {
      let mobileValid = false

      phone = phone.replace(/-/g, '')

      if (phone !== '') {
        if (phone.startsWith('+316')) {
          if (!phone.startsWith('+3160') && phone.length === 12) {
            mobileValid = true
          }
        } else if (phone.startsWith('00316')) {
          if (!phone.startsWith('003160') && phone.length === 13) {
            mobileValid = true
          }
        } else if (phone.startsWith('06')) {
          if (!phone.startsWith('060') && phone.length === 10) {
            mobileValid = true
          }
        } else if (phone.length === 8) {
          if (!phone.startsWith('0') && !phone.startsWith('+')) {
            mobileValid = true
          }
        } else if (phone.startsWith('+') && !phone.startsWith('+31') && phone.length > 3) {
          mobileValid = true
        } else if (phone.startsWith('00') && !phone.startsWith('0031') && phone.length > 4) {
          mobileValid = true
        }
      }
      return mobileValid
    },
    async getAvailableTimes (year, month, disableLoader) {
      if (typeof disableLoader === 'undefined' || disableLoader === false) {
        this.loadingNextMonth = true
      }
      if (this.isLoadingDates) {
        return
      }

      this.isLoadingDates = true
      let availabilityInfo = await this.$axiosSite.get(this.$axiosSite.routes.site + '/' + this.site.id + '/availability?year=' + year + '&month=' + parseInt(month))

      if (availabilityInfo !== null && typeof availabilityInfo.data !== 'undefined' && typeof availabilityInfo.data.result !== 'undefined') {
        this.availableTimes = availabilityInfo.data.result
        this.isLoadingDates = false

        let dateArray = []
        for (let date of Object.keys(availabilityInfo.data.result)) {
          dateArray.push(this.moment(date).tz('Europe/Amsterdam'))
        }
        this.availableDates = dateArray
        // if we have available dates, we should select the first one if it's not already selected to initialize the form
        if (this.availableDates.length > 0 && !this.availableDates.includes(this.moment(this.form.startDate).format('YYYY-MM-DD'))) {
          if (typeof disableLoader === 'undefined' || disableLoader === false) {
            this.setSelectedDate(this.availableDates[0])
          }
        }

        this.findMeetingTimeSlots()

        this.firstRun = false
        this.loadingNextMonth = false
      }
    },
    setSelectedDate (selectedDate) {
      this.form.startDate = selectedDate
      this.findMeetingTimeSlots()
    },
    setSelectedMonthYear (event) {
      this.year = event.year
      this.month = event.month
    },

    /**
     * Finds and sets the available meeting time slots for the selected date.
     * This method checks if there are available times for the selected date (`this.form.startDate`).
     * If available times are found, it sets `this.selectableTimes` to those times.
     * It then checks if the currently selected time slot (`this.form.timeSlot`) is within the available times.
     * If the selected time slot is not found, it sets the time slot to the first available time slot.
     * If no available times are found for the selected date, it sets `this.selectableTimes` to an empty array.
     */
    findMeetingTimeSlots () {
      const pickedDate = this.moment(this.form.startDate)
      if (typeof this.availableTimes[pickedDate.format('YYYY-MM-DD')] !== 'undefined') {
        this.selectableTimes = this.availableTimes[pickedDate.format('YYYY-MM-DD')]
        let timeFound = false
        for (let time of this.selectableTimes) {
          let timeSlotValue = this.moment(time.start).format('HH:mm') + '_' + this.moment(time.end).format('HH:mm')
          if (timeSlotValue === this.form.timeSlot) {
            timeFound = true
          }
        }
        if (!timeFound) {
          this.form.timeSlot = this.moment(this.selectableTimes[0].start).format('HH:mm') + '_' + this.moment(this.selectableTimes[0].end).format('HH:mm')
        }
      } else {
        this.selectableTimes = []
      }
    },
    toRgba (color, alpha = 1) {
      // Ensure the alpha is within the range 0–1
      if (alpha < 0 || alpha > 1) {
        console.warn('Alpha value should be between 0 and 1. Setting alpha to 1.')
        alpha = 1
      }

      let r, g, b

      if (color.startsWith('#')) {
        // Hex color format
        const bigint = parseInt(color.slice(1), 16)
        r = (bigint >> 16) & 255
        g = (bigint >> 8) & 255
        b = bigint & 255
      } else if (color.startsWith('rgb(')) {
        // RGB color format
        const rgbValues = color.match(/\d+/g) // Match all numbers
        if (rgbValues && rgbValues.length === 3) {
          [r, g, b] = rgbValues.map(Number)
        } else {
          console.warn('Invalid RGB color format.')
          return null
        }
      } else {
        return this.toRgba('#073c58', 0.3)
      }

      return { r, g, b, a: alpha }
    }
  },
  components: {
    languageSwitcher: () => import('../library/LanguageSwitcher'),
    WSDCalendar: () => import('../personal/Calendar.vue')
  },
  computed: {
    languageSwitcherComponent () {
      return 'languageSwitcher'
    },
    enabledDates () {
      return this.availableTimes
    }
  },
  watch: {
    year () {
      this.getAvailableTimes(this.year, this.month)
    },
    month () {
      this.getAvailableTimes(this.year, this.month)
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.prevRoute = from.name
    })
  },
  async mounted () {
    try {
      this.isFramed = window.self !== window.top
    } catch (e) {
      this.isFramed = true
    }
    this.findMeetingTimeSlots()

    let authInfo = await this.$axiosSite.post(this.$axiosSite.routes.login_site, {
      site_id: this.$route.params.site
    })
    if (authInfo && typeof authInfo.data !== 'undefined' && typeof authInfo.data.result !== 'undefined') {
      let token = authInfo.data.result.access_token
      this.$store.commit('setSiteAccessToken', token)
      this.$axiosSite.setToken(token)

      let siteInfo = await this.$axiosSite.get(this.$axiosSite.routes.site + '/' + this.$route.params.site + '?return_images=true')

      if (siteInfo && siteInfo.status === 200) {
        this.site = siteInfo.data.result
        console.log(this.site, 'site')
        let sheet = window.document.styleSheets[0]
        if (this.site.website_form_theme_background_color) {
          let originalColor = this.site.website_form_theme_background_color.replace('rgb(', '').replace(')', '')
          let outlineColor = this.site.website_form_theme_background_color.replace('rgb(', 'rgba(').replace(')', ',0.5)')
          let rgb = originalColor.split(',')
          let accentColor = this.shadeColor(rgb[0], rgb[1], rgb[2], 30)

          sheet.insertRule('#website-form .timeslot.active, #website-form .timeslot:hover, #website-form .submit { background-color: ' + this.site.website_form_theme_background_color + ' !important; border-color: ' + this.site.website_form_theme_background_color + ' !important; }', sheet.cssRules.length)
          sheet.insertRule('#website-form .submit:hover, #website-form .submit:active, #website-form .submit:focus { background-color: ' + accentColor + ' !important; border-color: ' + accentColor + ' !important; }', sheet.cssRules.length)
          sheet.insertRule('#website-form input:focus, #website-form input:active, #website-form textarea:focus, #website-form textarea:active, #website-form select:focus, #website-form select:active { border: 1px rgba(0,0,0,0) !important; box-shadow: 0 0 0 4px ' + outlineColor + ' !important; }', sheet.cssRules.length)
          sheet.insertRule('#website-form .submit:focus, #website-form .submit:active { box-shadow: 0 0 0 4px ' + outlineColor + ' !important; }', sheet.cssRules.length)
        }
        if (this.site.website_form_theme_contrast_color) {
          sheet.insertRule('#website-form .timeslot.active, #website-form .timeslot:hover, #website-form .submit, #website-form .submit:hover { color: ' + this.site.website_form_theme_contrast_color + ' !important; }', sheet.cssRules.length)
        }
        if (this.site.website_form_theme_body_background_color) {
          sheet.insertRule('body { background-color: ' + this.site.website_form_theme_body_background_color + ' !important; }', sheet.cssRules.length)
        }
        if (this.site.videochat_font_type) {
          sheet.insertRule('body { font-family: ' + this.site.videochat_font_type + ' !important; }', sheet.cssRules.length)
        }
        if (this.site.website_form_theme_text_color) {
          sheet.insertRule('body * { color: ' + this.site.website_form_theme_text_color + '; }', sheet.cssRules.length)
          sheet.insertRule('input, select, label { color: ' + this.site.website_form_theme_text_color + ' !important; }', sheet.cssRules.length)
        }

        if (this.site && this.site.website_form_theme_background_color) {
          const rgbColor = this.toRgba(this.site.website_form_theme_background_color, 0.3)
          document.documentElement.style.setProperty('--active-day-background-color-r', rgbColor.r)
          document.documentElement.style.setProperty('--active-day-background-color-g', rgbColor.g)
          document.documentElement.style.setProperty('--active-day-background-color-b', rgbColor.b)
          document.documentElement.style.setProperty('--active-day-background-color-a', rgbColor.a)
        }
        await this.getAvailableTimes(this.year, this.month)

        this.availableTimesInterval = setInterval(async () => {
          await this.getAvailableTimes(this.year, this.month, true)
        }, 30000)
      }
    }
  },
  beforeUnmount () {
    clearInterval(this.availableTimesInterval)
  }
}
</script>

<style lang="scss" scoped>
::v-deep .p-highlight .specialDay {
  background-color: inherit;
  color: inherit;
}
// This is used to override the special day css class from the calendar component
::v-deep .specialDay {
  height: inherit !important;
  width: inherit !important;
  border-radius: 50%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  color: var(--active-day-background-color);
  background-color: rgba(var(--active-day-background-color-r), var(--active-day-background-color-g), var(--active-day-background-color-b), var(--active-day-background-color-a));
}

</style>
